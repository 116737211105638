// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"JqMiTmOvTU_VQPJvdgkTr"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";
import {SamplingContext} from "@sentry/types";

process.env.DISABLE_SENTRY !== 'true' && Sentry.init({
  dsn: "https://20977a17d120c9694ae37f5cf8e496b3@sentry.z3.st/4",

  tracesSampleRate: process.env.ENV === "dev" ? 1 : 0.1,

  tracePropagationTargets: [/z3\.st$/],

  debug: false,

  replaysOnErrorSampleRate: 1.0,

  sampleRate: 0.1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.browserTracingIntegration(),
  ],

  environment: process.env.ENV,

  tracesSampler: ({name, attributes, parentSampled}: SamplingContext): number | boolean => {
    // Do not sample health checks ever
    if (name.includes("health-check")) {
      // Drop this completely by setting its sample rate to 0%
      return 0;
    }

    // Continue trace decision, if there is any parentSampled information
    if (typeof parentSampled === "boolean") {
      return parentSampled;
    }

    // Else, use default sample rate
    return 0.1;
  },
});
